























































































































































import { defineComponent, ref } from '@vue/composition-api'
import SolutionTitle from '@/components/SolutionTitle.vue'
import ArrowLink from '@/components/ArrowLink.vue'
import WebP from '@/components/WebP.vue'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import Strech from '@/components/animation/Strech.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'solutions',
  props: {
    solutions: {
      required: true,
      type: Array,
    },
    visual: {
      required: false,
    },
    headline: {
      required: false,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    content: {
      required: true,
      type: String,
    },
    link: {
      required: false,
      type: Object,
    },
  },
  components: {
    SolutionTitle,
    ArrowLink,
    WebP,
    LineSplitter,
    Strech,
  },
  setup() {
    const baselineRef = ref()
    const titleRef = ref()
    const contentRef = ref()
    const visualRef = ref()
    const solutionTitleRef = ref()
    const ctaRef = ref()

    const introAnimateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (baselineRef.value) {
          TL.add(baselineRef.value.getTween(), 'start')
        }

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start+=0.25')
        }

        if (contentRef.value) {
          TL.add(contentRef.value.getTween(), 'start+=0.5')
        }
      }
    }

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (visualRef.value) {
          TL.to(
            visualRef.value,
            {
              opacity: 1,
              y: 0,
              duration: 2.5,
            },
            'start'
          )
        }

        if (solutionTitleRef.value) {
          TL.to(
            solutionTitleRef.value,
            {
              opacity: 1,
              x: 0,
              stagger: 0.2,
              duration: 2,
            },
            'start'
          )
        }

        if (ctaRef.value) {
          TL.add(ctaRef.value.getTween(), '-=0.7')
        }
      }
    }

    return {
      animateIn,
      introAnimateIn,
      baselineRef,
      titleRef,
      contentRef,
      visualRef,
      solutionTitleRef,
      ctaRef,
    }
  },
})
